import React from 'react'
import './ListOfPros.css'

const ListOfPros = () => {
  return (
    <div id='ListOfPros'>
        <ul id='ListOfProgramSection'>
            <li>სალარო</li>
            <li>სამზარეულო</li>
            <li>საწყობი</li>
            <li>წარმოება</li>
            <li>მიწოდება</li>
            <li>პერსონალის მართვა</li>
            <li>ფინანსური ანალიტიკა</li>
        </ul>

        <div className='line' ></div>

        <ul id='listOfMainProst'>
            <li>მაგიდების ჯავშნის სისტემა</li>
            <li>მაგიდების გაერთიანება, შეკვეთების მიმოცვლა, შეკვეთების გაყოფა</li>
            <li>შეკვეთის მიღება, ადგილზე წაღება, გაუქმება</li>
            <li>კერძში ინგრედიენტების ცვლილება</li>
            <li>შერეული გადახდის მეთოდები</li>
            <li>შერეული ინტერნეტის გარეშე მუშაობის გამოცდილება</li>
            <li>სხვადასხვა ადგილმდებარეობს ერთ სისტემაში მართვა</li>
            <li>ტექნიკური მოწყობილობების მხარდაჭერა</li>
        </ul>
    </div>
  )
}

export default ListOfPros