import React, { useState } from "react";
import "./SlidingText.css";
import CupsnClouds from "../../assets/img/CC-Logo-POs.png"

const SlidingText = () => {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);

  return (
    <div 
      className="sliding-text-container" 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
    >
    <h2>ჩვენი კლიენტები</h2>
      <div 
        className={`sliding-text ${isPaused ? "paused" : ""}`}
      >
        <p> <img src={require('../../assets/img/logo.svg').default} className="sliding-logos" alt='mySvgImage' /> </p>
        <p> <img src={CupsnClouds} className="sliding-logos" alt="cc"/></p> 
        <p> გლორია </p>
        <p> იაკობის ეზო </p>
        <p> ბასიანი </p>
        <p> ჭაშნაგირი</p>
      </div>
    </div>
  );
};

export default SlidingText;