import React from 'react'
import './banner.css'
import ComputerBanner from '../../assets/img/3D Computer stand.png'

const Banner = () => {
  return (
    <div id='banner'>
        <h2><span id='p_Restaurant'>სარესტორნო</span><br />ბიზნესის<br />სრული<br /><span id='p_Automatization'>ავტომატიზაცია</span></h2>
        <img src={ComputerBanner} alt='computer banner' />
    </div>
  )
}

export default Banner