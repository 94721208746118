import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import SlidingText from './components/SlidingText/SlidingText';
import CanvasAnimation from './components/Canvas/CanvasAnimation';
import Syrve from './components/Syrve/Syrve';
import ListOfPros from './components/ListOfPros/ListOfPros';
import Subscriptions from './components/Subscriptions/Subscriptions';
import Features from "./components/Features/Features"


function App() {
  return (
    <div className="App">

    
    <Header />
    <Banner />
    <SlidingText />
    <Syrve />
    <ListOfPros />
    <Features />


    <CanvasAnimation />
      <header className="App-header">
        
      </header>
    </div>
  );
}

export default App;
