import React from 'react'
import LOGO from '../../assets/img/LOGO@1.5x.png'
import './header.css'
import { LuArrowUpRight } from "react-icons/lu";


const Header = () => {
  return (
    <div id='header'>
        <div></div>
        <h1><img src={LOGO} alt='Logo'/></h1>
        <p>უფასო დემონსტრაცია <LuArrowUpRight id='upRight' />
        </p>

    </div>
  )
}

export default Header