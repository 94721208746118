import React from 'react'
import './Syrve.css'

const Syrve = () => {
  return (
    <div id='Syrve'>
        <h2>Syrve</h2>
        <p>არის პროგრამული გადაწყვეტილება, რომელიც 
        განკუთვნილია რესტორნების, კაფეების და სხვა 
        ნებისმიერი სახეობის კვების ობიექტებისთვის 
        </p>

        
    </div>
  )
}

export default Syrve